import React from "react"
import { css } from "@emotion/core"

const CopyrightInfo = () => (
    <>
        <div
            css={css`
                text-align: center
            `}
        >
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby,</a>
            {` `}hosted on
            {` `}
            <a href="https://www.netlify.com">Netlify</a>
        </div>
    </>
)

export default CopyrightInfo