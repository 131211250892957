/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import CopyRightInfo from "../components/copyright-info"
import SEO from "../components/seo"

const TemplateBlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  return (
    <Layout>
      <SEO title={post.frontmatter.title} />

      <div
        sx={{
          m: `0 auto`,
          maxWidth: `900px`
        }}
      >
        <h1>{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html}} />
      </div>

      <div
        sx={{
          display: `flex`,
          justifyContent: `space-between`,
          m: `0 auto`,
          maxWidth: `900px`
        }}
      >
        <div>
          {previous && (
            <Link to={previous.fields.slug} rel="next">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </div>
        <div
          sx={{ textAlign: `right` }}
        >
          {next && (
            <Link to={next.fields.slug} rel="prev">
              {next.frontmatter.title} →
            </Link>
          )}
        </div>
      </div>

      <CopyRightInfo />

    </Layout>
  )

  
}

export default TemplateBlogPost

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
